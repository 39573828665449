//----------- NECESSARY FOR FASTBOOKING (HIGHEST PRIORITY) -------------//
import "./js/shared/loadForm.js";
import "./js/shared/tabsContainer.js";

//----------- Initialize Components and Shared dynamically -------------//
import BarceloComponents from "barcelo-components-init";
import BhgComponents from "bhg-components";
import BaseComponents from "./components.js";
import { BarceloShared } from "barcelo-shared-init";

const initializeComponents = async () => {
  try {
    BarceloShared.init();
    await BarceloComponents.init({ ...BhgComponents, ...BaseComponents });
    document.dispatchEvent(new Event("bcl-base-components-loaded"));
  } catch (error) {
    console.error("Error initializing components:", error);
  }
};

if (document.readyState === "loading") {
  document.addEventListener("DOMContentLoaded", initializeComponents);
} else {
  initializeComponents();
}

//----------- BARCELO Utilities -------------//
import "./js/general.js";
import "./js/utils/cookieUtils.js";
import "./js/utils/utag.js";

//----------- EMBED CODE Components -------------//
import "../../components/bhg/accordion";
import "../../components/bhg/tabs";

//----------- Shared JavaScript -------------//
import "./js/shared/countdown.js";
import "./js/shared/currency.js";
import "./js/shared/dataAnchor.js";
import "./js/shared/hotelReturn.js";
import "./js/shared/modal.js";
import "./js/shared/modalMeetingRoom.js";
import "./js/shared/profile.js";
import "./js/shared/progressBar.js";
import "./js/shared/promo-countdown.js";
import "./js/shared/recaptchav3.js";
import "./js/shared/scrollUp.js";
import "./js/shared/searchHotel.js";
import "./js/shared/sticky.js";
import "./js/shared/tabs.js";
import "./js/shared/translate-on-the-fly.js";

//----------- My Barcelo Components -------------//
import "../../components/barcelo/mybarcelo/myb-edit-profile/js/c-myb-edit-profile.js";
import "../../components/barcelo/mybarcelo/myb-trigger-banner/js/c-myb-trigger-banner.js";

//----------- Shared JS with Components -------------//
import "./js/shared/360.js";
import "./js/shared/flipcard.js";
import "./js/shared/globalEvent.js";
import "./js/shared/rte-tooltip.js";
import "./js/shared/tooltip.js";

//----------- Vendors -------------//
import "./libraries/datepicker/fecha.min.js";
import "./libraries/datepicker/hotel-datepicker.js";

// Other Components
import "../../components/barcelo/forms/logged-update-form/js/logged-update-form.js";

//-------------------------------- LOAD SCRIPTS -------------------------------------//

const internalScripts = ["/etc.clientlibs/barcelo/clientlibs/clientlib-mybarcelo.js"];

internalScripts.forEach((src) => {
  bcl.u.loadScript({
    src: src,
    callback: function () {
      console.log("loaded clientlib: " + src);
    },
    type: "text/javascript",
    defer: true,
  });
});

const externalScripts = ["https://www.barcelo.com/static/assets/js/splitContainers.js"];

externalScripts.forEach((src) => {
  const script = document.createElement("script");
  script.src = src;
  script.type = "text/javascript";
  script.defer = true;
  script.onload = function () {
    console.log("loaded external script: " + src);
  };
  document.head.appendChild(script);
});

//-------------------------------- LOAD STYLES -------------------------------------//
const internalStyles = ["/etc.clientlibs/barcelo/clientlibs/clientlib-mybarcelo.css"];

internalStyles.forEach((href) => {
  bcl.u.loadStyleSheet(href);
});

// const externalStyles = [];

// externalStyles.forEach((href) => {
//   const link = document.createElement("link");
//   link.href = href;
//   link.type = "text/css";
//   link.rel = "stylesheet";
//   link.onload = function () {
//     console.log("loaded external style: " + href);
//   };
//   document.head.appendChild(link);
// });
