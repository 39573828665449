//----------- Necessary imports -------------//
import { checkDynamicLoaders } from "./utils/dynamicLoaders.js";
import { checkDynamicComponents } from "./utils/dynamicComponentsStylesheet.js";

import "../../base/js/init.js";
import "../../base/js/utils/preCommonUtils.js";
import "../../../components/bhg/image.js";
import "../../base/js/utils/ajaxUtils.js";
import "../../base/js/utils/commonUtils.js";
import "../../base/js/shared/loading.js";
import "../../base/js/utils/customContextHubUtils.js";
import "../../base/js/utils/lastjs.js";

//----------- Fastbooking -------------//
import "../../../components/barcelo/structure/fastbooking/js/commonFastbookingProps.js";

const main = async function () {
  //----------- Control dynamic loaders -------------//
  checkDynamicLoaders();

  //----------- Control dynamic components -------------//
  checkDynamicComponents();

  //----------- Load RTL if necessary -------------//
  const detectedLanguages = ["ar-AE"];
  const currentLang = document.documentElement.lang;

  const loadRTL = function () {
    if (detectedLanguages.includes(document.documentElement.lang)) {
      bcl.u.loadStyleSheet("/etc.clientlibs/barcelo/clientlibs/clientlib-rtl.css");
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      observer.disconnect();
    }
  };

  const observer = new MutationObserver(loadRTL);

  if (detectedLanguages.includes(currentLang)) {
    loadRTL();
  } else {
    observer.observe(document.documentElement, { attributes: true, attributeFilter: ["lang"] });
  }
};

if (document.readyState !== "loading") {
  main();
} else {
  document.addEventListener("DOMContentLoaded", main, true);
}
