const styleSheetsArray = Object.entries({
  ".hotel-royal-level": ["/etc.clientlibs/barcelo/clientlibs/clientlib-hotelRoyalLevel.css", false],
  ".c-hightlights-services-banner": ["/etc.clientlibs/barcelo/clientlibs/clientlib-hightlightServiceBanner.css", false],
  ".c-hotel-card": ["/etc.clientlibs/barcelo/clientlibs/clientlib-hotelCard.css", false],
  ".mod--horizontal-card-compact": ["/etc.clientlibs/barcelo/clientlibs/clientlib-hotelHorizontalCompact.css", false],
  ".c-meeting__table, .c-meeting__modal, .meeting__table": ["/etc.clientlibs/barcelo/clientlibs/clientlib-meetingTable.css", false],
  ".c-flip-card": ["/etc.clientlibs/barcelo/clientlibs/clientlib-flipCard.css", false],
  ".c-card-hotel, .c-card-hotel-list, .c-label--tag-all-included": ["/etc.clientlibs/barcelo/clientlibs/clientlib-cardHotel.css", false],
  ".cmp-breadcrumb, .breadcrumb": ["/etc.clientlibs/barcelo/clientlibs/clientlib-breadcrumb.css", false],
  ".c-textimage, .textimage": ["/etc.clientlibs/barcelo/clientlibs/clientlib-textImage.css", false],
  ".c-label": ["/etc.clientlibs/barcelo/clientlibs/clientlib-label.css", false],
  ".c-submenu-container": ["/etc.clientlibs/barcelo/clientlibs/clientlib-submenuContainer.css", false],
  ".c-card-hotel-list__content, .c-card-hotel-list": ["/etc.clientlibs/barcelo/clientlibs/clientlib-cardHotelList.css", false],
  ".info-service__wrapper, .c-info-service": ["/etc.clientlibs/barcelo/clientlibs/clientlib-infoServicePage.css", false],
  ".meetings-comparator": ["/etc.clientlibs/barcelo/clientlibs/clientlib-cardHotelCompactMeeting.css", false],
  ".offer-collection-list, .meetings-comparator": ["/etc.clientlibs/barcelo/clientlibs/clientlib-offerCollectionList.css", false],
  ".c-collection-list": ["/etc.clientlibs/barcelo/clientlibs/clientlib-collectionList.css", false],
  "footer, .footer, .xpf_reference_footer": ["/etc.clientlibs/barcelo/clientlibs/clientlib-footer.css", false],
  ".c-container, .generic-container": ["/etc.clientlibs/barcelo/clientlibs/clientlib-container.css", false],
  ".c-modal, .tingle-modal": ["/etc.clientlibs/barcelo/clientlibs/clientlib-customModal.css", false],
  ".c-carousel": ["/etc.clientlibs/barcelo/clientlibs/clientlib-carousel.css", false],
  header: ["/etc.clientlibs/barcelo/clientlibs/clientlib-header.css", false],
  ".c-myb-menu, .c-myb-menu--login": ["/etc.clientlibs/barcelo/clientlibs/clientlib-cMybMenu.css", false],
  ".c-coupon": ["/etc.clientlibs/barcelo/clientlibs/clientlib-coupon.css", false],
  ".c-myb-current-bookings": ["/etc.clientlibs/barcelo/clientlibs/clientlib-mybCurrentBooking.css", false],
  ".c-myb-booking-history": ["/etc.clientlibs/barcelo/clientlibs/clientlib-mybBookingHistory.css", false],
}).map(([selector, [styleSheet, emitEventOnLoad]]) => ({
  selector,
  styleSheet,
  emitEventOnLoad,
}));

const loadedStyleSheets = new Set();

const loadDynamicStyles = function (styleSheet, emitEventOnLoad) {
  bcl.u.loadStyleSheet(styleSheet, undefined, emitEventOnLoad);
  loadedStyleSheets.add(styleSheet);
};

const checkAndLoad = function (entry, root = document) {
  const { selector, styleSheet, emitEventOnLoad } = entry;
  if (!loadedStyleSheets.has(styleSheet)) {
    if (root.querySelector(selector)) {
      loadDynamicStyles(styleSheet, emitEventOnLoad);
    }
  }
};

const loadExistingStyles = function () {
  styleSheetsArray.forEach((entry) => {
    checkAndLoad(entry, document);
  });
};

const handleMutations = function (mutationsList) {
  const addedElements = [];

  for (const mutation of mutationsList) {
    if (mutation.type === "childList") {
      mutation.addedNodes.forEach((node) => {
        if (node.nodeType === Node.ELEMENT_NODE) {
          addedElements.push(node);
        }
      });
    }
  }
  if (addedElements.length === 0) {
    return;
  }

  styleSheetsArray.forEach((entry) => {
    const { selector, styleSheet, emitEventOnLoad } = entry;

    if (!loadedStyleSheets.has(styleSheet)) {
      for (const el of addedElements) {
        if (el.matches(selector)) {
          loadDynamicStyles(styleSheet, emitEventOnLoad);
          break;
        }
        if (el.querySelector && el.querySelector(selector)) {
          loadDynamicStyles(styleSheet, emitEventOnLoad);
          break;
        }
      }
    }
  });
};

const startDynamicObservation = function () {
  loadExistingStyles();

  const mutationObserver = new MutationObserver(handleMutations);
  mutationObserver.observe(document.body, {
    childList: true,
    subtree: true,
  });
};

const checkDynamicComponents = function () {
  startDynamicObservation();
};

export { checkDynamicComponents };
