import { BarceloComponent } from "barcelo-components";

const MenuComponents = {
  stripeMenu: BarceloComponent({
    selector: ".c-stripemenu",
    path: "menu/stripemenu/js/c-stripemenu",
  }),

  vMenu: BarceloComponent({
    selector: ".c-v-menu, .header",
    path: "menu/v-menu/js/v-menu",
  }),
};

export default MenuComponents;
