const checkDynamicLoaders = function () {
  const globalLoader = document.getElementById("global-loader");
  if (!globalLoader) {
    return;
  }

  const visibleSet = new Set();

  const intersectionObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        visibleSet.add(entry.target);
      } else {
        visibleSet.delete(entry.target);
      }
    });

    if (visibleSet.size === 0) {
      globalLoader.remove();
      intersectionObserver.disconnect();
    }
  });

  const loaders = document.querySelectorAll(".component-loader .loader-spinner");
  if (loaders.length === 0) {
    globalLoader.remove();
  } else {
    loaders.forEach((loader) => intersectionObserver.observe(loader));
  }
};

export { checkDynamicLoaders };
