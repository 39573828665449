import assetVideo from "./asset-video";

const PIXELS_PRECISION_VIDEOS = 100;
const MAX_WIDTH_VIDEOS = 2000;
const MAX_HEIGHT_VIDEOS = 2000;

let plyrStyleConfigured = false;
let hlsLibraryLoaded = false;
let plyrLibraryLoaded = false;
const downloadedLibs = new Set();
const videoDebug = false;

const lazyVideoHelper = {
  addLazyVideos(baseFind) {
    const videoSelectors = [".video-generic", ".video-youtube", ".video-vimeo"];
    const observer = new IntersectionObserver(this.handleIntersection.bind(this), {
      threshold: [0],
    });

    videoSelectors.forEach((selector) => {
      baseFind.querySelectorAll(selector).forEach((element, i) => {
        element.id += Date.now() + i;
        if (element.getAttribute("vdmodal") !== "true") {
          observer.observe(element);
        }
      });
    });
  },

  handleIntersection(entries, observer) {
    entries.forEach(({ isIntersecting, target }) => {
      if (isIntersecting) {
        this.lazyLoad(target);
        observer.unobserve(target);
      }
    });
  },

  lazyLoad(lazyVideo) {
    if (lazyVideo.id.includes("GNPlayer")) {
      this.setupCustomPlayer(lazyVideo);
    } else if (lazyVideo.getAttribute("vdautoplay") === "true") {
      lazyVideo.src = lazyVideo.src.replace("&autoplay=0", "&autoplay=1");
    }
  },

  async setupCustomPlayer(lazyVideo) {
    await this.setPosterURL(lazyVideo);

    if (lazyVideo.getAttribute("vdautoplay") === "true") {
      Object.assign(lazyVideo, {
        muted: true,
        autoplay: true,
        loop: true,
        playsInline: true,
      });
    }

    assetVideo.setupBestSource(lazyVideo);

    const dynamicMedia = lazyVideo.getAttribute("dynamicMedia") === "true";
    const canPlayNativeDM = lazyVideo.getAttribute("canPlayNativeDM") === "true";

    if (dynamicMedia && canPlayNativeDM) {
      lazyVideo.load();
    } else {
      await this.customPlayerLoadAndPlay(lazyVideo);
    }
  },

  async customPlayerLoadAndPlay(video) {
    await this.loadPlyrLibs();
    this.configurePlyrStyle();
    const player = new window.Plyr(video, this.getPlyrConfig(video));
    this.setupPlyrEvents(player);
    this.setupHlsIfNeeded(video);
  },

  async loadPlyrLibs() {
    if (!plyrLibraryLoaded) {
      await Promise.all([import("https://cdn.jsdelivr.net/npm/plyr@3/+esm").then((plyr) => (window.Plyr = plyr.default)), this.downloadExternalLib("https://cdn.jsdelivr.net/npm/plyr@3/dist/plyr.min.css", "css")]);
      plyrLibraryLoaded = true;
    }
  },

  configurePlyrStyle() {
    if (plyrStyleConfigured) {
      return;
    }
    plyrStyleConfigured = true;

    const styleContent = `
      .plyr { height: 100%; width: 100%; }
      .plyr__poster {
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-size: cover !important;
      }
      .plyr--video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    `;

    const style = document.createElement("style");
    style.textContent = styleContent;
    document.head.appendChild(style);
  },

  getPlyrConfig(video) {
    const controls = video.getAttribute("vdcontrols") === "true" ? ["play-large", "play", "progress", "current-time", "mute", "volume", "settings", "pip", "airplay", "fullscreen"] : ["play-large"];

    return {
      debug: videoDebug,
      autoplay: video.getAttribute("vdautoplay") === "true",
      muted: video.getAttribute("vdmuted") === "true",
      loop: { active: video.loop },
      poster: video.dataset.poster,
      playsinline: true,
      controls: controls,
      hideControls: true,
      settings: ["speed", "loop"],
      clickToPlay: true,
      disableContextMenu: true,
      displayDuration: true,
      invertTime: true,
      autopause: true,
      keyboard: { focused: true, global: false },
      fullscreen: { enabled: true, fallback: true, iosNative: false, container: null },
      storage: { enabled: false, key: "plyr" },
      speed: { selected: 1, options: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2] },
      captions: { active: false },
      ads: { enabled: false },
    };
  },

  setupPlyrEvents(player) {
    const playButton = player.elements.buttons?.play?.[0];
    if (playButton) {
      playButton.style.display = player.autoplay ? "none" : "block";
    }

    ["ready", "progress", "playing", "pause"].forEach((event) => player.on(event, (e) => videoDebug && console.log(`Player ${event}: `, e)));

    window.barceloActivePlayers = window.barceloActivePlayers || [];
    window.barceloActivePlayers.push(player);
  },

  async setupHlsIfNeeded(video) {
    if (this.isDynamicMediaConfigured(video)) {
      if (!hlsLibraryLoaded) {
        await import("https://cdn.jsdelivr.net/npm/hls.js@1/+esm").then((hlsJs) => (window.Hls = hlsJs.default));
        hlsLibraryLoaded = true;
      }

      const hls = new window.Hls(this.getHlsConfig());
      hls.loadSource(video.querySelector(".videoDynamicPath").src);
      hls.attachMedia(video);
      if (videoDebug) {
        window.hls = hls;
      }

      // Error control
      hls.on(window.Hls.Events.ERROR, function (event, data) {
        if (data.fatal) {
          switch (data.type) {
            case window.Hls.ErrorTypes.NETWORK_ERROR:
              hls.startLoad();
              break;
            case window.Hls.ErrorTypes.MEDIA_ERROR:
              hls.recoverMediaError();
              break;
            default:
              window.hls.destroy();
              break;
          }
        } else {
          console.warn("HLS error", data);
        }
      });
    } else {
      video.src = video.querySelector(".videoDynamicPath").src;
      video.load();
    }
  },

  isDynamicMediaConfigured(video) {
    return video.getAttribute("dynamicmedia") === "true" && (video.getAttribute("videodesktoppathdm") !== "DM-not-configured" || video.getAttribute("videomobilepathdm") !== "DM-not-configured");
  },

  getHlsConfig() {
    return {
      debug: videoDebug,
      autoStartLoad: true,
      startPosition: -1,
      startLevel: -1,
      capLevelToPlayerSize: true,

      // Buffer
      maxBufferLength: 10,
      maxMaxBufferLength: 20,
      maxBufferHole: 2,
      backBufferLength: 30,

      // Performance
      enableWorker: true,
      enableSoftwareAES: false,
      maxFragLookUpTolerance: 0.2,
      testBandwidth: true,

      // Low-latency and progressive
      lowLatencyMode: false,
      progressive: false,
      startFragPrefetch: true,

      // Subtitles
      enableWebVTT: false,
      enableLowInitialPlaylist: true,

      // Timeouts and retries
      fragLoadingTimeOut: 15000,
      fragLoadingMaxRetry: 4,
      fragLoadingRetryDelay: 2000,
      fragLoadingMaxRetryTimeout: 30000,
    };
  },

  modalCheckEnableVideo(element) {
    if (element.id.includes("GNPlayer")) {
      this.setupCustomPlayer(element);
    } else if (element.getAttribute("vdautoplay") === "true") {
      element.src = element.src.replace("&autoplay=0", "&autoplay=1");
    }
    this.watchModalVideo(element);
  },

  modalCheckDisableVideo(element) {
    if (element.id.includes("GNPlayer")) {
      this.disableCustomPlayer(element);
    } else {
      element.src = element.src.replace("&autoplay=1", "&autoplay=0");
    }
  },

  watchModalVideo(element) {
    const modal = element.closest(".tingle-modal, .brh-video__modal");
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "attributes" && mutation.attributeName === "class") {
          this.modalCheckDisableVideo(element);
          observer.disconnect();
        }
      });
    });
    observer.observe(modal, { attributes: true });
  },

  async setPosterURL(video) {
    const fallbackPoster = video.dataset.posterpreview;
    if (fallbackPoster) {
      video.poster = fallbackPoster;
    }

    try {
      const response = await fetch(`${video.dataset.posterpreview}/jcr:content/metadata.infinity.json`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const data = await response.json();
      const newPoster = this.constructPosterURL(data, video);
      video.dataset.poster = newPoster;
      video.poster = newPoster;
    } catch (error) {
      console.info("No metadata found: " + error);
    }
  },

  constructPosterURL(data, video) {
    const imageServer = `${data["dam:scene7Domain"]}is/image/`;
    return data["dam:scene7File"] ? `${imageServer}${data["dam:scene7File"]}?wid=${this.getOptimalWidth(video)}&hei=${this.getOptimalHeight(video)}&fit=crop,1&qlt=45&cache=validate,on,on` : video.dataset.posterpreview;
  },

  getOptimalWidth(video) {
    return Math.min(video.clientWidth + PIXELS_PRECISION_VIDEOS, MAX_WIDTH_VIDEOS);
  },

  getOptimalHeight(video) {
    return Math.min(video.clientHeight + PIXELS_PRECISION_VIDEOS, MAX_HEIGHT_VIDEOS);
  },

  disableCustomPlayer(element) {
    if (window.barceloActivePlayers) {
      const playerIndex = window.barceloActivePlayers.findIndex((p) => p.elements?.original === element);
      if (playerIndex > -1) {
        window.barceloActivePlayers[playerIndex].destroy();
        window.barceloActivePlayers.splice(playerIndex, 1);
      }
    }
  },

  async downloadExternalLib(url, type) {
    if (downloadedLibs.has(url)) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      let element;

      if (type === "js") {
        element = document.createElement("script");
        element.src = url;
        element.defer = true;
        element.fetchpriority = "high";
      } else if (type === "css") {
        const preloadLink = document.createElement("link");
        preloadLink.rel = "preload";
        preloadLink.href = url;
        preloadLink.as = "style";
        preloadLink.onload = () => {
          const styleLink = document.createElement("link");
          styleLink.rel = "stylesheet";
          styleLink.href = url;
          document.head.appendChild(styleLink);
          downloadedLibs.add(url);
          resolve();
        };
        preloadLink.onerror = reject;
        document.head.appendChild(preloadLink);
        return;
      } else {
        return reject(new Error("Unsupported file type for downloading"));
      }

      element.onload = () => {
        downloadedLibs.add(url);
        resolve();
      };

      element.onerror = reject;
      document.head.appendChild(element);
    });
  },
};

export default lazyVideoHelper;
